import Joi from "joi";

export const create = Joi.object({
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .required()
});

export const update = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required(),
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .required(),
    enabled: Joi.boolean()
        .optional(),
    company: Joi.object()
        .keys({
            name: Joi.string()
                .min(4)
                .max(255)
                .trim()
                .optional()
                .allow(null, ""),
            address: Joi.string()
                .min(3)
                .max(100)
                .trim()
                .optional()
                .allow(null, ""),
            phone: Joi.string()
                .min(9)
                .max(14)
                .pattern(/^[+0-9]+$/)
                .optional().allow(null,""),
            nip: Joi.string()
                .min(4)
                .max(20)
                .optional()
                .allow(null)
                .allow("")
        }),
    terms: Joi.object()
});
