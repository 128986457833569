<template>
    <Dialog :title="$t('entity.create.title')" :open="open" :saveProgress="saveProgress" @cancel="$emit('cancel')" @save="save()">
        <v-text-field
            v-model="form.name"
            :label="$t('entity.form.name')"
            :error-messages="errors.name"
            :hide-details="!errors.name"
            :disabled="saveProgress"
            @change="validate('name')"
            outlined
            prepend-inner-icon="mdi-office-building"
        />
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";
import Settings from "../Settings.vue";
import validator from "../../plugins/validator";
import * as entityValidation from "../../api/entity/validation";

export default {
    components: {
        Dialog,
        Settings
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        }
    },

    data: () => ({
        form: {
            name: null
        },

        errors: {
            name: null
        },

        show: true,
        saveProgress: false
    }),

    async mounted() {},

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data().errors);

            return validator(
                this.form,
                entityValidation.create,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if (!this.validate()) return;

            this.saveProgress = true;
            let res = await this.$store.dispatch("api/entity/create", this.form);
            this.saveProgress = false;

            if (res.status == 200) {
                this.$emit("save");
                this.$emit("cancel");
            } else {
                if (res.data.error.code == "EntityExists") {
                    this.errors.name = this.$t("entity.create.create_exists");
                } else {
                    this.errors.name = res.data.error.message;
                }
            }
        }
    }
};
</script>
