<template>
    <Page icon="mdi-office-building" :title="$t('routes.entities')" :progress="progress">
        <ActionBar @filter="s => load(s)" :searchProgress="searchProgress">
            <v-btn 
                @click="dialog = true"
                class="primary"
            >
                <v-icon left>mdi-plus</v-icon> {{ $t("entity.create.title") }}
            </v-btn>
        </ActionBar>

        <v-simple-table class="mt-3">
            <thead>
                <tr>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('entity.list.name')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('entity.list.namecompany')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="company.name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text w-action">
                        {{ $t("entity.list.edit") }}
                    </th>
                </tr>
            </thead>

            <tbody style="white-space: nowrap;">
                <tr v-for="(e, index) in entities" :key="index">
                    <td class="pt-3 pb-3">
                        <tr>
                            <span class="font-weight-bold">{{ e.name }}</span>
                        </tr>
                        <tr>
                            <span class="grey--text text--darken-2">
                                <v-icon size="16">mdi-music-accidental-sharp</v-icon> {{ e._id }}
                            </span>
                        </tr>
                    </td>

                    <td>
                        {{ (e.company.name) ? e.company.name : $t("empty") }}
                    </td>

                    <td class="w-action">
                        <v-btn
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'entity', params: { id: e._id, routefrom: 'entities' } }"
                        >
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                        
                        <v-btn
                            class="ml-1 noActiveButton"
                            fab
                            :color="activeEntityId() == e._id ? 'primary' : 'secondary'"
                            x-small
                            dark
                            depressed
                            @click="$store.dispatch('api/user/selectEntity', e)"
                        >
                            <v-icon>mdi-account-arrow-right</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination
            class="mt-3"
            @input="load(form.filter)"
            v-if="pagination.total > 1"
            v-model="form.page"
            :length="pagination.total"
            :total-visible="7"
        />

        <EntityCreate 
            v-if="dialog" 
            :open="dialog" 
            @cancel="dialog = false" 
            @save="load(form.filter)" 
        />
    </Page>
</template>

<style scoped>
    .noActiveButton:focus::before {
        opacity: 0 !important;
    }
</style>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import SortHeader from "./components/SortHeader.vue";
import EntityCreate from "./dialogs/EntityCreate.vue";

export default {
    components: {
        ActionBar,
        Page,
        EntityCreate,
        SortHeader
    },

    async mounted() {
        this.load();
    },

    data: () => ({
        progress: true,
        searchProgress: false,
        dialog: false,

        pagination: {
            total: 0,
            filter: null
        },

        form: {
            page: 1,
            limit: null,
            filter: null,
            sort: "name ",
        },

        entities: null
    }),
    
    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load(filter) {
            if (filter) this.form.filter = filter;
            else this.form.filter = null;

            if(this.pagination.filter != filter) this.form.page = 1;
            this.pagination.filter = filter;

            this.searchProgress = !this.progress;

            const res = await this.$store.dispatch("api/entity/all", this.form);

            if(res.task.count == 0) {
                this.progress = false;
                this.searchProgress = false;

                if(res.status == 200) {
                    this.entities = res.data;
                    this.pagination.total = res.pages;
                }
            }
        },

        activeEntityId(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return this.$store.state.api.user.data.entity._id;
            return entity._id;
        }
    }
};
</script>
